<template>
  <!-- 导航 -->
  <div class="homeNavPC">
    <img class="homeNavPC-flag" src="@/assets/image/home/headerFlag.png" />
    <div class="homeNavPC-header">
      <img src="@/assets/image/home/headerTitle.png" />
      <form class="homeNavPC-search">
        <input placeholder="请输入关键字进行搜索" type="text" />
        <iconfont class="homeNavPC-search-icon" size="24px" name="search" />
      </form>
      <img src="@/assets/image/home/headerPhone.png" />
    </div>
    <div class="homeNavPC-list">
      <router-link to="/home" class="homeNavPC-item homeNavPC-item_active"
        >爱月宝首页</router-link
      >
      <router-link to="/brand" class="homeNavPC-item">品牌介绍</router-link>
      <router-link to="/caregiver" class="homeNavPC-item">月嫂</router-link>
      <router-link to="/nanny" class="homeNavPC-item">育婴师</router-link>
      <router-link to="/postpartum" class="homeNavPC-item"
        >产后康复</router-link
      >
      <router-link to="/university" class="homeNavPC-item"
        >月嫂课堂</router-link
      >
      <!--router-link to="/joinUs" class="homeNavPC-item">招商加盟</router-link>-->
      <router-link to="/about/job" class="homeNavPC-item">加入我们</router-link>
    </div>
  </div>

  <!-- 导航（移动） -->
  <div class="homeNavMobile">
    <div class="homeNavMobile-header">
      <img
        class="homeNavMobile-header-logo"
        src="@/assets/image/public/header.png"
      />
      <div class="homeNavMobile-header-menu" @click="showList = !showList">
        <iconfont name="menu" size="50rpx" />
      </div>
    </div>
    <div class="homeNavMobile-list" v-if="showList">
      <router-link
        to="/home"
        class="homeNavMobile-item homeNavMobile-item_active"
        >爱月宝首页</router-link
      >
      <router-link to="/brand" class="homeNavMobile-item">品牌介绍</router-link>
      <router-link to="/caregiver" class="homeNavMobile-item">月嫂</router-link>
      <router-link to="/nanny" class="homeNavMobile-item">育婴师</router-link>
      <router-link to="/postpartum" class="homeNavMobile-item"
        >产后康复</router-link
      >
      <router-link to="/university" class="homeNavMobile-item"
        >月嫂课堂</router-link
      >
      <!--router-link to="/joinUs" class="homeNavMobile-item"
        >招商加盟</router-link
      >-->
      <router-link to="/about/job" class="homeNavMobile-item">加入我们</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "homeHeader",
  props: {
    detail: String,
  },
  data() {
    return {
      showList: false,
    };
  },
  unmounted() {
    // 销毁事件
    removeEventListener("click", this.cancelMenu);
  },
  mounted() {
    this.init();
  },
  methods: {
    /**
     * 初始化
     */
    init() {
      addEventListener("click", this.cancelMenu);
    },
    /**
     * 取消菜单
     */
    cancelMenu(e) {
      if (!document.querySelector(".homeNavMobile").contains(e.target)) {
        this.showList = false;
      }
    },
  },
};
</script>


<style scoped lang="scss">
.homeNavMobile {
  display: none;
}

// 导航
.homeNavPC {
  width: 1200px;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1001;

  // 顶部
  &-header {
    display: flex;
    position: absolute;
    right: 0;

    img {
      display: block;
      object-fit: contain;
    }
  }

  // 搜索
  &-search {
    margin-top: 49px;
    height: 44px;
    width: 482px;
    border: 2px solid #ed207b;
    border-radius: 23px;
    background-color: #fff;
    position: relative;

    input {
      background-color: transparent;
      border: none;
      display: block;
      width: stretch;
      height: stretch;
      outline: none;
      padding-left: 20px;
      padding-right: 50px;
    }

    &-icon {
      position: absolute;
      pointer-events: none;
      right: 19px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  // 旗帜
  &-flag {
    // box-shadow: 0 0 10px 0 rgba($color: #000000, $alpha: 0.2);
    position: absolute;
    left: 0;
    width: 132px;
    height: 252px;
    z-index: 1001;
    filter: drop-shadow(0 0 5px rgba($color: #000000, $alpha: 0.1));
  }

  // 导航列表
  &-list {
    position: absolute;
    top: 124px;
    left: 0;
    right: 0;
    display: flex;
    background-color: #ed207b;
    line-height: 66px;
    padding-left: 132px;
    border-radius: 34px;
  }

  // 导航元素
  &-item {
    color: #fff;
    flex: 1;
    text-align: center;

    &_active {
      background-color: #9bdbda;
    }
  }
}
</style>

<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  .homeNavPC {
    display: none;
  }

  // 导航
  .homeNavMobile {
    width: 100%;
    height: rpx(90);
    display: block;
    position: sticky;
    top: 0;
    background-color: #FFF;
    z-index: 101;

    &-header {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-logo {
        height: 100%;
      }

      &-menu {
        margin-right: rpx(20);
        color: #ed207b;
      }
    }

    // 导航列表
    &-list {
      position: absolute;
      top: rpx(90);
      left: 0;
      right: 0;
      z-index: 1001;
      line-height: rpx(90);
      background-color: #fff;
    }

    // 导航元素
    &-item {
      display: block;
      color: #404040;
      font-size: rpx(28);
      border-bottom: 1px dashed #f2f2f2;
      text-align: center;

      &_active {
        color: #fff;
        background-color: #9bdbda;
      }
    }
  }
}
</style>
