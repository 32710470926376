<template>
  <div class="publicFooter">
    <div class="publicFooter-frame">
      <div class="publicFooter-detail">
        <!-- 顶部 -->
        <div class="publicFooter-header">
          <div class="publicFooter-header-main">联系我们</div>
          <div class="publicFooter-header-sub">CONTACT US</div>
        </div>
        <!-- 联系内容 -->
        <div class="publicFooter-contact">
          <div class="publicFooter-contact-item">
            <iconfont
              size="18px"
              class="publicFooter-contact-item-icon"
              name="location"
            />
            {{ footer.address }}
          </div>
          <div class="publicFooter-contact-item">
            <iconfont
              size="18px"
              class="publicFooter-contact-item-icon"
              name="phone"
            />
            电话：{{ footer.telephone }}
          </div>
          <!-- <div class="publicFooter-contact-item">
            <iconfont
              size="18px"
              class="publicFooter-contact-item-icon"
              name="mail"
            />
            邮箱：{{ footer.telephone }}
          </div> -->
          <div class="publicFooter-contact-item">
            <iconfont
              size="18px"
              class="publicFooter-contact-item-icon"
              name="route"
            />
            路线：<br>㈠周边公交<br>
⑴敦化路连云港路站：401路环形，622路<br>
⑵青岛阜外医院站：12路，210路，211路，307路，308路，309路<br>
⑶档案馆站：205路环形，211路，307路，320路，626路环形<br>
⑷辽阳西路抚顺路站：28路环形，119路，362路，602路<br>
㈡周边地铁<br>
地铁3号线（敦化路站下车）<br>
㈢火车站乘车线路<br>
青岛站：乘坐地铁3号线，在敦化路站下车，步行960米即可到达。<br>
青岛北站：乘坐地铁3号线，在敦化路站下车，步行960米即可到达。
          </div>
        </div>
        <!-- 版权所有 -->
        <div class="publicFooter-copyright">
          <div class="publicFooter-copyright-item">
            版权所有：{{ footer.copyright }}
          </div>
          <div class="publicFooter-copyright-item">备案号：<a href="https://beian.miit.gov.cn/">{{ footer.casenumber }}</a></div>
        </div>
      </div>
      <!-- 二维码 -->
      <div class="publicFooter-qrcode">
        <img :src="footer.qrcodeimage" />
        微信公众号
      </div>
      <!-- 地图 -->
      <div class="publicFooter-map">
        <img :src="footer.coordinateimage" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "publicFooter",
  computed: {
    ...mapState(["footer"]),
  },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  .publicFooter {
    height: 573px;
    min-width: 1200px;
    color: #fff;
    background-image: url("~@/assets/image/public/footer.jpg");

    &-frame {
      margin: auto;
      width: 1200px;
      display: flex;
      height: 100%;
      justify-content: space-between;
      align-items: center;
    }

    &-header {
      &-main {
        font-size: 32px;
      }

      &-sub {
        font-size: 14px;
      }
    }

    // 联系方式
    &-contact {
		width: 450px;
		font-size: 14px;
		overflow: auto;
		margin-top: 12px;

      &-item {
        display: flex;
        align-items: center;
        margin-top: 15px;

        &-icon {
          margin-right: 10px;
        }
      }
    }

    // 版权
    &-copyright {
      margin-top: 40px;
      border-top: 1px solid #fff;
      padding-top: 12px;

      &-item {
        margin-top: 12px;
      }
    }

    // 二维码
    &-qrcode {
      width: 160px;
      height: 160px;
      text-align: center;

      img {
        @include cover;
        margin-bottom: 10px;
      }
    }

    // 地图
    &-map {
      width: 550px;
      height: 360px;

      img {
        @include cover;
      }
    }
  }
}
</style>


<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  .publicFooter {
    // height: rpx(330);
    box-sizing: border-box;
    padding: rpx(30);
    color: #fff;
    background-image: url("~@/assets/image/public/footer.jpg");

    &-frame {
      margin: auto;
      width: stretch;
      display: flex;
      height: 100%;
      justify-content: space-between;
      align-items: center;
    }

    &-header {
      &-main {
        font-size: rpx(28);
      }

      &-sub {
        font-size: rpx(14);
      }
    }

    // 联系方式
    &-contact {
      font-size: rpx(18);
      overflow: auto;
      margin-top: rpx(18);

      &-item {
        display: flex;
        align-items: center;
        margin-top: rpx(10);

        &-icon {
          margin-right: rpx(10);
        }
      }
    }

    // 版权
    &-copyright {
      margin-top: rpx(20);
      border-top: rpx(2) solid #fff;
      padding-top: rpx(20);
      font-size: rpx(16);

      &-item {
        margin-top: rpx(10);
      }
    }

    // 二维码
    &-qrcode {
      display: none;
    }

    // 地图
    &-map {
      display: none;
      
    }
  }
}
</style>
