<template>
  <container ref="home" class="home" @error="init">
    <!-- 顶部 -->
    <homeHeader />

    <!-- 轮播 -->
    <swiper
      class="banner"
      :pagination="{ clickable: true }"
      :autoplay="{ delay: 3000, disableOnInteraction: false }"
    >
      <swiper-slide v-for="(item, index) in banner.list" :key="index">
        <img class="banner-image" :src="item.navimage" />
      </swiper-slide>
    </swiper>

    <!-- 选择服务 -->
    <div class="selectService">
      <router-link
        class="selectService-item"
        :to="{ path: item.linkurl }"
        v-for="(item, index) in card.list"
        :key="index"
      >
        <img class="selectService-item-img" :src="item.navimage" />
      </router-link>
    </div>

    <!-- 服务特色 -->
    <publicSection
      class="serviceFeatures"
      :header="require('@/assets/image/home/serviceFeatures.png')"
    >
      <div class="serviceFeatures-content">
        <div
          class="serviceFeatures-item"
          v-for="(item, index) in features.list"
          :key="index"
        >
          <img class="serviceFeatures-item-cover" :src="item.navimage" />
          <div class="serviceFeatures-item-title">{{ item.title }}</div>
          <div class="serviceFeatures-item-intro">{{ item.intro }}</div>
          <div class="serviceFeatures-item-description">
            {{ item.description }}
          </div>
        </div>
      </div>
    </publicSection>

    <!-- 品牌简介 -->
    <publicSection
      class="brandIntroduction"
      :header="require('@/assets/image/home/brandIntroduction.png')"
    >
      <swiper
        class="brandIntroduction-swiper"
        :autoplay="{ delay: 3000, disableOnInteraction: false }"
      >
        <swiper-slide v-for="(item, index) in brand.list" :key="index">
          <img class="brandIntroduction-swiper-image" :src="item.brandimage" />
        </swiper-slide>
      </swiper>
    </publicSection>

    <!-- 底部 -->
    <publicFooter></publicFooter>

    <!-- 联系 -->
    <contact></contact>
  </container>
</template>

<script>
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
require("swiper/components/pagination/pagination.scss");
require("swiper/components/navigation/navigation.scss");
// import "swiper/components/pagination/pagination.scss";
// import "swiper/components/navigation/navigation.scss";
import homeHeader from "@/components/homeHeader.vue";
import publicFooter from "@/components/publicFooter.vue";
import publicSection from "@/components/publicSection.vue";
import contact from "@/components/contact.vue";

SwiperCore.use([Pagination, Navigation, Autoplay]);

export default {
  name: "home",
  components: {
    Swiper,
    SwiperSlide,
    homeHeader,
    publicFooter,
    publicSection,
    contact,
  },
  data() {
    return {
      // 横幅
      banner: {
        list: [],
      },
      // 卡片
      card: {
        list: [],
      },
      // 特色
      features: {
        list: [],
      },
      // 品牌
      brand: {
        list: [],
      },
    };
  },
  mounted() {
    // 获取数据
    this.init();
  },
  methods: {
    /**
     * 初始化数据
     */
    async init() {
      try {
        // 设置状态
        this.$refs.home.status("init", {
          height: "100vh",
        });

        // 获取数据
        const [banner, card, features, brand] = await Promise.all([
          this.$axios("/api/mainstation/homeCarousel"),
          this.$axios("/api/mainstation/homeCardown"),
          this.$axios("/api/mainstation/homeService"),
          this.$axios("/api/mainstation/homeBrandcarousel"),
        ]);

        // 设置数据
        this.banner.list = banner.data;
        this.card.list = card.data;
        this.features.list = features.data;
        this.brand.list = brand.data;

        // 设置状态
        this.$refs.home.status("default");
      } catch (e) {
        // 设置状态
        this.$refs.home.status("error", {
          text: e.message,
          height: "100vh",
        });
      }
    },

    // /**
    //  * 底部轮播
    //  */
    // brandIntroductionModules() {
    //   return [Navigation, Pagination];
    // },

    // /**
    //  * 顶部轮播
    //  */
    // bannerModules() {
    //   return [Navigation, Pagination];
    // },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  // 横幅
  .banner {
    height: 830px;
    min-width: 1200px;
    width: 100%;

    &::v-deep {
      .swiper-pagination-bullet {
        background-color: #9bdbd9;
      }
      .swiper-pagination-bullet-active {
        background-color: #ed207b;
      }
      .swiper-button-prev {
        left: calc(100% / 2 - 600px);
        color: #ed207b;
      }
      .swiper-button-next {
        right: calc(100% / 2 - 600px);
        color: #ed207b;
      }
    }

    &-image {
      @include cover;
      user-select: none;
    }
  }

  // 选择服务
  .selectService {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 1200px;
    margin: auto;
    margin-top: 60px;
    padding: 20px;

    // 选择服务-元素
    &-item {
      width: 560px;
      height: 290px;
      margin: 20px;

      &-img {
        @include cover;
      }
    }
  }

  // 特色服务
  .serviceFeatures {
    margin-top: 74px;

    &-content {
      width: 1200px;
      margin: auto;
      margin-top: 40px;
      display: block;
      display: flex;
      justify-content: center;
    }

    // 特色服务-元素
    &-item {
      width: 285px;
      height: 320px;
      margin: 0 5px;
      border-radius: 10px;
      overflow: hidden;
      background-color: #f9f9f9;

      // 特色服务-元素-封面
      &-cover {
        @include cover;
        height: 170px;
        width: 100%;
      }

      // 特色服务-元素-封面
      &-title {
        color: #ed207b;
        font-size: 24px;
        text-align: center;
        margin: 10px;
      }

      // 特色服务-元素-简介
      &-intro {
        color: #999999;
        font-size: 16px;
        text-align: center;
        margin: 10px;
      }

      // 特色服务-元素-说明
      &-description {
        color: #666666;
        font-size: 16px;
        text-align: center;
        margin: 10px;
      }
    }
  }

  // 品牌简介
  .brandIntroduction {
    margin-top: 74px;

    &::v-deep {
      .swiper-button-prev {
        left: calc(100% / 2 - 600px);
        color: #ed207b;
      }
      .swiper-button-next {
        right: calc(100% / 2 - 600px);
        color: #ed207b;
      }
    }

    // 轮播
    &-swiper {
      margin-top: 35px;
      margin-bottom: 90px;

      &-image {
        @include cover;
        margin: auto;
        width: 1200px;
        height: 310px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  // 横幅
  .banner {
    height: rpx(320);
    width: rpx(750);

    &-image {
      @include cover;
      user-select: none;
    }

    &::v-deep {
      .swiper-pagination-bullet {
        background-color: #9bdbd9;
      }
      .swiper-pagination-bullet-active {
        background-color: #ed207b;
      }
      .swiper-button-prev,
      .swiper-button-next {
        color: #ed207b;
        &::after {
          font-size: rpx(80);
        }
      }
    }
  }

  // 选择服务
  .selectService {
    display: flex;
    width: rpx(750);
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    margin-top: rpx(40);

    // 选择服务-元素
    &-item {
      margin: rpx(15);
      width: rpx(336);
      height: rpx(174);

      &-img {
        @include cover;
      }
    }
  }

  // 特色服务
  .serviceFeatures {
    margin-top: rpx(40);

    &-content {
      width: stretch;
      margin-top: rpx(40);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    // 特色服务-元素
    &-item {
      width: rpx(342);
      height: rpx(420);
      border-radius: rpx(10);
      margin: rpx(11);
      overflow: hidden;
      background-color: #f9f9f9;

      // 特色服务-元素-封面
      &-cover {
        @include cover;
        height: rpx(240);

        // 285*170
      }

      // 特色服务-元素-封面
      &-title {
        color: #ed207b;
        font-size: rpx(28);
        text-align: center;
        margin: rpx(10);
      }

      // 特色服务-元素-简介
      &-intro {
        color: #999999;
        font-size: rpx(24);
        text-align: center;
        margin: rpx(10);
      }

      // 特色服务-元素-说明
      &-description {
        color: #666666;
        font-size: rpx(24);
        text-align: center;
        margin: rpx(10);
      }
    }
  }

  // 品牌简介
  .brandIntroduction {
    margin-top: rpx(40);

    // 轮播
    &-swiper {
      margin-top: rpx(40);
      margin-bottom: rpx(40);

      &-image {
        @include cover;
        margin: auto;
        width: rpx(750);
        height: rpx(300);
      }

      &::v-deep {
        .swiper-pagination-bullet {
          background-color: #9bdbd9;
        }
        .swiper-pagination-bullet-active {
          background-color: #ed207b;
        }
        .swiper-button-prev,
        .swiper-button-next {
          color: #ed207b;
          &::after {
            font-size: rpx(80);
          }
        }
      }
    }
  }
}
</style>
