<template>
  <div class="contactPC" @click="line">
    <img src="@/assets/image/public/contact.png" />
  </div>
  <div class="contactMobile">
    <div class="contactMobile-item contactMobile-item_tel" @click="tel">
      <iconfont class="contactMobile-item-icon" size="32rpx" name="phone" />
      电话咨询
    </div>
    <div class="contactMobile-item contactMobile-item_line" @click="line">
      <iconfont class="contactMobile-item-icon" size="32rpx" name="line"  />
      在线咨询
    </div>
  </div>
</template>

<script>
export default {
  name: "contact",
  props: {},
  computed: {},
  methods: {
    /**
     * 电话资讯
     */
    tel() {
      location.assign(`tel:${this.$store.state.footer.telephone}`);
    },
    /**
     * 在线资讯
     */
    line() {location.assign(`${this.$store.state.footer.kfurl}`);},
  },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  .contactMobile {
    display: none;
  }

  .contactPC {
    position: fixed;
    bottom: 100px;
    left: 0;
    z-index: 101;
    width: 160px;
    height: 150px;
    cursor: pointer;
    user-select: none;
  }
}
</style>

<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  .contactPC {
    display: none;
  }

  .contactMobile {
    height: rpx(100);
    display: flex;
    position: sticky;
    bottom: 0;
    z-index: 101;

    &-item {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      // line-height: rpx(100);
      font-size: rpx(28);

      &-icon {
        margin-right: rpx(15);
        margin-top: rpx(5);
      }

      &_tel {
        background-color: #ed207b;
        color: #fff;
      }

      &_line {
        background-color: #9bdbd9;
        color: #fff;
      }
    }
  }
}
</style>
